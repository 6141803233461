<template>
  <main class="kb-main" id="kb-checklist-dtl" v-if="!isLoadingCrse">
<!--    <LxpMobileHeader :title="crseDist.distCrseNm" :header-class="false">-->
<!--      <template v-slot:left>-->
<!--        <div class="util util-back">-->
<!--          <a class="util-actions util-actions-back" @click="goBackFunc">-->
<!--            <i class="icon-history-back" />-->
<!--          </a>-->
<!--        </div>-->
<!--      </template>-->
<!--    </LxpMobileHeader>-->

    <div class="main-content min-component p-2" v-if="!isLoading">
      <template v-if="pageMode == 'list'">
        <template v-if="checklist">
          <p v-if="checklist.missTaskCount > 0" class="fw-bolder mb-2">&#8251; 금일 기준 미처리 업무가 {{checklist.missTaskCount}}건 있습니다.</p>
          <nav class="kb-nav-round-tabs py-1 mb-0" data-tabs="sticky">
            <ul class="kb-nav-list" v-if="checklist.steps">
              <li class="kb-nav-item" v-for="(step,idx) in checklist.steps" :key="idx">
                <a class="kb-nav-link" :class="{'is-active': isActive(step.stepSn)}" @click="changeActive(step.stepSn)"><span class="kb-nav-text">{{step.stepNm}}</span></a>
              </li>
            </ul>
          </nav>
          <div class="board-list-container board-list-bordered">
            <ul class="board-list">
              <li v-for="(task,idx) in activeTasks" :key="idx" class="board-list-item">
                <div>
                  <div class="board-title"><span class="title">{{task.taskNm}}</span></div>
                  <div class="board-meta" v-if="task.notiTxt"><span class="text">{{task.notiTxt}}</span></div>
                  <div class="board-meta">
                    <span class="text" v-if="task.fnshYn == 'Y'" >시한 {{task.taskDate}}</span>
                    <span class="text text-red" v-else-if="task.fnshYn != 'Y' && task.taskDate < today" >시한 {{task.taskDate}}</span>
                    <span class="text text-blue" v-else >시한 {{task.taskDate}}</span>
                  </div>
                </div>
                <div v-if="task.fnshYn == 'Y'" class="board-meta text-end">
                  <span class="text">{{task.fnshNm}}<br>{{timestampToDateFormat(task.fnshDt, 'yyyy.MM.dd hh:mm')}}</span>
                </div>
                <button v-else class="kb-btn kb-btn-sm kb-btn-primary" @click="writeTaskNote(task)">확인</button>
              </li>
            </ul>
          </div>
        </template>
        <template v-else>
          <div class="error-page mt-3">
            <div class="images">
              <img src="@/assets/lxp/images/common/img_empty.png" />
            </div>
            <p class="description">등록된 체크리스트가 없습니다.</p>
          </div>
        </template>
      </template>
      <template v-else-if="pageMode == 'write'">
        <div class="kb-form-fields kb-form-fields-v2">
          <div class="kb-form-row">
            <label class="kb-form-label">
              <span class="kb-form-label-text">업무명</span>
            </label>
          </div>
          <div class="kb-form-row">
            <input type="text" class="kb-form-control" v-model="taskNote.taskNm" readonly />
          </div>
          <div class="kb-form-row">
            <label class="kb-form-label">
              <span class="kb-form-label-text">처리 상태</span>
            </label>
          </div>
          <div class="kb-form-row">
            <div class="kb-form-check">
              <input type="radio" class="kb-form-check-input" name="prgsStt" id="prgsStt_I" v-model="taskNote.param.prgsStt" value="I">
              <label for="prgsStt_I" class="kb-form-check-label">진행중</label>
              <input type="radio" class="kb-form-check-input" name="prgsStt" id="prgsStt_E" v-model="taskNote.param.prgsStt" value="E">
              <label for="prgsStt_E" class="kb-form-check-label">완료</label>
            </div>
          </div>
          <div class="kb-form-row">
            <label class="kb-form-label">
              <span class="kb-form-label-text">제목</span>
            </label>
          </div>
          <div class="kb-form-row">
            <input type="text" class="kb-form-control" v-model="taskNote.param.noteTitle" placeholder="제목을 입력하세요" maxlength="200" />
          </div>
          <div class="kb-form-row">
            <label class="kb-form-label">
              <span class="kb-form-label-text">내용</span>
            </label>
          </div>
          <div class="kb-form-row">
            <textarea class="kb-form-control kb-form-textarea" v-model="taskNote.param.noteCn" placeholder="내용을 입력하세요" rows="5" style="resize: none" />
          </div>
          <div class="kb-form-row row-buttons">
            <button class="kb-btn kb-btn-primary" @click="saveTaskNote">저장</button>
            <button class="kb-btn kb-btn-secondary" @click="changePageMode('list')">취소</button>
          </div>
        </div>
      </template>
    </div>
  </main>
</template>

<script>
import {onMounted, watch, ref, computed, reactive} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";
// import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import {
  ACT_GET_CRSE_DIST_CHECKLIST,
  ACT_GET_CRSE_DIST_INFO,
  ACT_INSERT_CRSE_DIST_CHECKLIST_TASK_NOTE
} from "@/store/modules/checklist/checklist";
import {getItem, getTodayDate, isSuccess, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {goBackFunc} from "@/assets/js/modules/common/common";

export default {
  name: 'ChecklistDtl',
  // components: {LxpMobileHeader},
  setup() {
    const store = useStore()
    const route = useRoute()
    const {showConfirm, showMessage} = useAlert()
    const isLoading = ref(true)
    const isLoadingCrse = ref(true)
    const pageMode = ref('')
    const crseDist = ref(null)
    const checklist = ref(null)
    const activeStep = ref(null)
    const activeTasks = ref([])
    const today = computed(() => {return getTodayDate('yyyy-mm-dd')})
    const taskNote = reactive({
      taskNm: '',
      param: {
        mstTaskSn: 0,
        taskSn: 0,
        prgsStt: '',
        noteTitle: '',
        noteCn: '',
      }
    })

    const changePageMode = (name) => {
      pageMode.value = name;
      if(pageMode.value == 'list'){
        initTaskNote();
      }
    }

    const changeActive = (num) => {
      activeStep.value = num;
    }

    const isActive = (num) => {
      return activeStep.value == num;
    }

    const initTaskNote = () => {
      taskNote.taskNm = '';
      taskNote.param.mstTaskSn = 0;
      taskNote.param.taskSn = 0;
      taskNote.param.noteTitle = '';
      taskNote.param.noteCn = '';
    }

    const writeTaskNote = (task) => {
      taskNote.taskNm = task.taskNm;
      taskNote.param.mstTaskSn = task.mstTaskSn;
      taskNote.param.taskSn = task.taskSn;
      taskNote.param.noteTitle = task.taskNm;
      taskNote.param.prgsStt = 'E';
      changePageMode('write');
    }

    const saveTaskNote = () => {
      if(!taskNote.param.noteTitle){
        showMessage('제목을 입력하세요.')
      }else if(!taskNote.param.prgsStt){
        showMessage('처리 상태를 선택하세요.')
      }else{
        showConfirm('저장하시겠습니까?', () => {
          store.dispatch(`checklist/${ACT_INSERT_CRSE_DIST_CHECKLIST_TASK_NOTE}`, taskNote.param).then((res) => {
            if(isSuccess(res)){
              showMessage('저장되었습니다.', () => {
                getChecklistMst();
                changePageMode('list');
              });
            }
          })
        });
      }
    }

    const setActiveTasks = () => {
      activeTasks.value = checklist.value.steps.find(x => x.stepSn == activeStep.value).tasks;
    }

    const getChecklistMst = () => {
      isLoading.value = true;
      store.dispatch(`checklist/${ACT_GET_CRSE_DIST_CHECKLIST}`, route.params.distCrseSn).then((res) => {
        if(lengthCheck(res)){
          checklist.value = getItem(res);
          if(checklist.value.steps.length > 0 && !activeStep.value){
            activeStep.value = checklist.value.steps[0].stepSn;
          }
        }else{
          checklist.value = null;
          activeTasks.value = [];
        }
      }).finally(() => {
        isLoading.value = false;
      })
    }

    const getCrseDistInfo = () => {
      isLoadingCrse.value = true;
      store.dispatch(`checklist/${ACT_GET_CRSE_DIST_INFO}`, route.params.distCrseSn).then((res) => {
        if(lengthCheck(res)){
          crseDist.value = getItem(res);
          getChecklistMst();
          changePageMode('list');
        }else{
          showMessage('차수 정보를 불러오지 못했습니다.');
          goBackFunc();
        }
      }).finally(() => {
        isLoadingCrse.value = false;
      })
    }

    watch(() => checklist.value, () => {
      setActiveTasks();
    })

    watch(() => activeStep.value, () => {
      setActiveTasks();
    })

    onMounted(() => {
      getCrseDistInfo();
    })

    return {
      isLoading,
      isLoadingCrse,
      pageMode,
      crseDist,
      checklist,
      activeStep,
      activeTasks,
      today,
      taskNote,
      changePageMode,
      changeActive,
      isActive,
      writeTaskNote,
      saveTaskNote,
      timestampToDateFormat,
      goBackFunc,
    }
  }
}
</script>