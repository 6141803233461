<template>
  <main class="kb-main" id="kb-support">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="support-view-container">

        <div class="view-contents">
          <!-- content-section -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">알리미 발송 정보</h4>
            </header>
            <div class="desc">
              {{notiObjInfo.chapNm}} : {{notiObjInfo.objNm}}
            </div>
            <div class="section-content">
              <div class="kb-form-fields kb-form-fields-v2">

                <div class="kb-form-field" v-if="view != 'attend'">
                  <div class="kb-form-row">
                    <label>
                      <span class="kb-form-label-text">발송채널</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                      <label class="form-check customCheck">
                        <input
                            v-model="chnl.kakao"
                            type="checkbox"
                            class="form-check-input"
                            value="Y"
                        >
                        <span class="form-check-label m-r-5">카카오알림톡</span>
                      </label>
                      <label class="form-check customCheck">
                        <input
                            v-model="chnl.liiv"
                            type="checkbox"
                            class="form-check-input"
                            value="Y"
                        >
                        <span class="form-check-label m-r-5">리브똑똑</span>
                      </label>
                  </div>
                </div>

                <div class="kb-form-field">
                  <div class="kb-form-row">
                    <label>
                      <span class="kb-form-label-text">예약발송</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <label class="form-check customCheck">
                      <input v-model="notiParams.rsvtTrsmYn" class="form-check-input" type="radio" value="N" name="rsvt">
                      <span class="form-check-label">즉시</span>
                    </label>
                    <label class="form-check customCheck">
                      <input v-model="notiParams.rsvtTrsmYn" class="form-check-input" type="radio" value="Y" name="rsvt">
                      <span class="form-check-label">예약</span>
                    </label>
                  </div>
                </div>


                <div class="kb-form-field" v-if="notiParams.rsvtTrsmYn == 'Y'">
                  <div class="kb-form-row">
                    <label>
                      <span class="kb-form-label-text">예약일시</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <Datepicker
                        v-model="tmpParam"
                        v-model:toggle="toggles.rsvtDate"
                        :disabled="notiParams.rsvtTrsmYn!='Y'"
                        @setYmd="setYmd"
                    />
                  </div>
                  <div class="kb-form-row">
                    <select v-model="tmpParam.rsvtHH"  class="kb-form-control kb-form-select w-25" style="width:100px; float:left">
                      <option v-for="n in 24" :key="n-1" :value="fillZero(n-1)">{{fillZero(n-1)}}</option>
                    </select><span style="float:left; width:10%;padding-left:7px" class="font-body14B mt-4"> 시</span>
                    <select v-model="tmpParam.rsvtMM"  class="kb-form-control kb-form-select w-25" style="width:100px; float:left">
                      <option v-for="n in 12" :key="(n-1)*5" :value="fillZero((n-1)*5)">{{fillZero((n-1)*5)}}</option>
                    </select><span style="float:left; width:10%;padding-left:7px" class="font-body14B mt-4"> 분</span>
                  </div>
                </div>

                <div class="kb-form-field">
                  <div class="kb-form-row">
                    <label>
                      <span class="kb-form-label-text">발송내용</span>
                    </label>
                  </div>
                  <div class="kb-form-row">
                    <textarea
                        v-model="notiParams.notiCn"
                        class="h-100px bg-light"
                        style="padding: 7px 7px 8px 9px"
                        :placeholder="view=='attend'?'알림 발송 탬플릿의 **||col5||**로 들어갈 문구를 입력 바랍니다.':'발송내용작성'"
                    >
                    </textarea>
                  </div>
                </div>

              </div>
              <div class="kb-btn-actions pt-3">
                <button class="kb-btn-support mb-3 kb-btn-silver" style="width:26%" @click="closeModal()">발송취소</button>


                <button class="kb-btn-support kb-btn-primary" style="width:40%;margin-left: 3%" @click="sendTestQRAlt()" v-if="view=='attend'">TEST알림톡발송</button>
                <button class="kb-btn-support bg-danger" style="width:29%;margin-left: 2%" @click="sendQRAlt()" v-if="view=='attend'">알림톡발송</button>
                <button class="kb-btn-support kb-btn-primary" style="width:64%;margin-left: 6%" @click="sendLink()" v-if="view=='research'">설문발송</button>
              </div>
            </div>
          </section>

        </div>
      </div>
      <div class="popup-close">
        <button @click="trainingInstituteApplyStep0Modal = false" type="button" class="kb-btn-popup-close"><i
            class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>

  </main>

</template>

<script>
import {computed, reactive} from "vue";
import Datepicker from "@/components/otherdept/common/Datepicker.vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {useAlert} from "@/assets/js/modules/common/alert";
import {ACT_INSERT_SRV_NOTI_SEND, ACT_INSERT_TEST_NOTI_SEND_QR_ATTEND, ACT_INSERT_NOTI_SEND_QR_ATTEND} from "@/store/modules/checklist/checklist";
import {isSuccess} from "@/assets/js/util";
export default {
  name: "NotiSendInfo",
  components: {Datepicker},
  emits: ['update:modelValue', 'objInfo'],
  props:{
    modelValue: Boolean,
    objInfo : Object,
    distNm : String
  },
  setup(props, {emit}){
    const route = useRoute();
    const store = useStore();
    const {showMessage, showConfirm} = useAlert();
    const notiObjInfo = computed(()=>props.objInfo);
    const crseDistNm = computed(()=>props.distNm);
    const view = computed(()=> route.query.view);

    const toggles = reactive({
      rsvtDate:false,
    });

    const today = new Date();

    const tmpParam=reactive({
      year:today.getFullYear(),
      month:today.getMonth()+1,
      day:today.getDate(),
      rsvtHH:'00',
      rsvtMM:'00',
    });

    const setYmd=(div, y, m, d)=>{
      tmpParam.year = y;
      tmpParam.month = m;
      tmpParam.day = d;
    }

    const chnl = reactive({
      kakao:false,
      liiv:false
    });

    const notiParams = reactive({
      notiMngSn: 0,
      rsvtTrsmYn: 'N', // 예약발송여부
      rsvtTrsmDt: 0,
      aprvrId: '',
      aprvrNm: '',

      kakaoNotiYn: view.value=='attend'?'Y':'N',
      liivNotiYn: 'N',

      trnNotiYn: 'N',
      tmplYn: 'N',
      atrzYn: 'N',
      stt: '00',
      notiMsgDomains : [],
      notiTrgtDomains: [],

      division: 'TimetableSurvey',
      distCrseSn: 0,

      notiCn:view.value=='attend'?'':'[설문]'+crseDistNm.value+' : ' + notiObjInfo.value.chapNm
    });

    const fillZero = (n) =>{
      let val= n.toString();
      if(val.length < 2){
        val = "0"+val;
      }
      return val;
    }

    const closeModal=()=>{
      emit('update:modelValue', false);
      emit('objInfo', null);
    };

    const insertNotification = () => {
      console.log(notiParams.notiCn);
        store.dispatch(`checklist/${ACT_INSERT_SRV_NOTI_SEND}`, {distSvySn:notiObjInfo.value.distSvySn, params:{...notiParams}}
      ).then(res => {
            if(isSuccess(res)) {
              showMessage({
                text: `발송요청이 등록되었습니다. 통합결재에서 승인처리 하시기 바랍니다.`,
                callback: () => {
                  closeModal;
                }
              });
            }
          }).catch(e => {
            console.error(e);
          });
    }

    const insertQRNotification = (rsvtTrsmDt) => {
      store.dispatch(`checklist/${ACT_INSERT_NOTI_SEND_QR_ATTEND}`, {
        rsvtTrsmDt: rsvtTrsmDt,
        distCrseSn: route.params.distCrseSn,
        lrnObjDtlDistSn: props.objInfo.lrnObjDtlDistSn,
        col5: notiParams.notiCn
      }).then(res => {
        if(isSuccess(res)) {
          showMessage({
            text: `발송등록이 완료되었습니다.`,
            callback: () => {
              closeModal()
            }
          });
        }
      });
    }

    const insertTestQRNotification = (rsvtTrsmDt) => {
      store.dispatch(`checklist/${ACT_INSERT_TEST_NOTI_SEND_QR_ATTEND}`, {
        rsvtTrsmDt: rsvtTrsmDt,
        distCrseSn: route.params.distCrseSn,
        lrnObjDtlDistSn: props.objInfo.lrnObjDtlDistSn,
        col5: notiParams.notiCn
      }).then(res => {
        if(isSuccess(res)) {
          showMessage({
            text: `테스트 발송등록이 완료되었습니다.`,
            callback: () => {
              closeModal()
            }
          });
        }
      });
    }
    const sendQRAlt=()=>{

      if (notiParams.notiCn === '') {
        showMessage('탬플릿의 **||col5||**로 들어갈 문구를 입력 바랍니다.');
        return ;
      }

      if (notiParams.rsvtTrsmYn === "Y") {
        notiParams.rsvtTrsmDt = Date.parse(tmpParam.year+"-"+('0' + tmpParam.month).slice(-2)+"-"+('0' + tmpParam.day).slice(-2)+" "+tmpParam.rsvtHH+":"+tmpParam.rsvtMM+":00");
      } else {
        notiParams.rsvtTrsmDt = new Date('1970-01-01 09:00:00');
      }

      showConfirm({
        text: '알림 발송 하시겠습니까?',
        callback: () => {
          insertQRNotification(notiParams.rsvtTrsmDt);
        }
      });
    }
    const sendTestQRAlt=()=>{

      if (notiParams.notiCn === '') {
        showMessage('탬플릿의 **||col5||**로 들어갈 문구를 입력 바랍니다.');
        return ;
      }

      if (notiParams.rsvtTrsmYn === "Y") {
        notiParams.rsvtTrsmDt = Date.parse(tmpParam.year+"-"+('0' + tmpParam.month).slice(-2)+"-"+('0' + tmpParam.day).slice(-2)+" "+tmpParam.rsvtHH+":"+tmpParam.rsvtMM+":00");
      } else {
        notiParams.rsvtTrsmDt = new Date('1970-01-01 09:00:00');
      }

      showConfirm({
        text: '테스트 알림 발송 하시겠습니까?',
        callback: () => {
          insertTestQRNotification(notiParams.rsvtTrsmDt);
        }
      });
    }

    const sendLink=()=>{
      notiParams.distCrseSn = route.params.distCrseSn;
      // notiParams.distSvySn = notiObjInfo.value.distSvySn;

      if (notiParams.rsvtTrsmYn === "Y") {
        notiParams.rsvtTrsmDt = Date.parse(tmpParam.year+"-"+('0' + tmpParam.month).slice(-2)+"-"+('0' + tmpParam.day).slice(-2)+" "+tmpParam.rsvtHH+":"+tmpParam.rsvtMM+":00");
      } else {
        notiParams.rsvtTrsmDt = null;
      }

      if(chnl.liiv){
        notiParams.liivNotiYn = "Y";
      }else{
        notiParams.liivNotiYn = "N";
      }

      if(chnl.kakao){
        notiParams.kakaoNotiYn = "Y";
      }else{
        notiParams.kakaoNotiYn = "N";
      }

      if (notiParams.kakaoNotiYn == "N" && notiParams.liivNotiYn == "N") {
        showMessage('발송채널을 선택해주세요.');
        return false;
      }

      // insertNotification();

      showConfirm('알림 발송 하시겠습니까?', () => {
          insertNotification();
        }
      );
    }

    return{
      notiObjInfo,
      notiParams,
      fillZero,
      tmpParam,
      toggles,
      setYmd,
      sendLink,
      closeModal,
      sendTestQRAlt,
      sendQRAlt,
      view,
      chnl
    }
  }
}
</script>