<template>

  <main class="kb-main" id="kb-support" @click="closeModal">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="support-view-container">

        <div class="view-contents">
          <!-- content-section -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">출석 현황</h4>
            </header>
            <div class="fw-bolder mb-2">
              {{objInfo.chapNm}} : {{objInfo.objNm}}
            </div>
          </section>

          <div class="board-list-container board-list-bordered">
            <ul class="board-list">
              <li v-for="(item,idx) in items" :key="idx" class="board-list-item pb-0" style="min-height:0">
                <div :class="item.rsltYn!='Y'?'text text-red':''">
                  <span>{{(idx+1)}}. {{item.lrnerNm}}({{item.lrnerId}})/{{item.jbgdNm}}/{{item.deptNm}}</span>
                  <span v-if="item.rsltYn=='Y'"><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{timestampToDateFormat(item.regDt, 'yyyy-MM-dd hh:mm:ss')}}</span>
                </div>
              </li>
            </ul>
          </div>

          <div class="error-page" style="margin-top: 50px" v-if="items == null">
            <div class="images">
              <img src="@/assets/lxp/images/common/img_empty.png" />
            </div>
            <p class="description">데이터가 없습니다.</p>
          </div>
        </div>

      </div>
    </div>
  </main>
</template>

<script>
import {onMounted, ref, watch} from "vue";
import {ACT_GET_NOW_USER_STATUS_LIST} from "@/store/modules/checklist/checklist";
import {getItems, getTodayTimestamp, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {useRoute} from "vue-router";
import {useStore} from "vuex";

export default {
  name: "NowUsersStatus",
  methods: {getTodayTimestamp, timestampToDateFormat},
  props:{
    modelValue: Boolean,
    objInfo : {
      type:Object,
      default:null
    },
    distNm : String
  },
  setup(props, {emit}){
    const route = useRoute();
    const store = useStore();
    const items = ref(null);

    const closeModal=()=>{
      emit('update:modelValue', false);
      emit('objInfo', null);
    };

    const getNowUserStatusList=()=>{
      // isLoadingCrse.value = true;
      store.dispatch(`checklist/${ACT_GET_NOW_USER_STATUS_LIST}`, {
        distCrseSn:route.params.distCrseSn,
        objDistSn:props.objInfo.lrnObjDtlDistSn
      }).then((res) => {
        if(lengthCheck(res)){
          items.value = getItems(res);
        }else{
          items.value = null;
        }
      })
    }

    watch(() => props.objInfo.lrnObjDtlDistSn, () => {
      getNowUserStatusList();
    });

    onMounted(()=>{
      if (props.objInfo != null) {
        getNowUserStatusList();
      }
    });

    return{
      closeModal,
      items
    }
  }
}
</script>