
<template>

  <main class="kb-main" id="kb-support" v-if="!isNotiSendInfo && !isQrAttend && !isNowAttend">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="search-result">
        <div class="view-contents">

          <!-- content-section -->
          <section v-for="(item, idx) in items" :key="idx" class="content-section">
            <div class="content-item bd-clear pd-clear">
              <div class="title">{{ item.chapNm}}</div>
              <div class="desc">
                <p><b>{{ item.objNm}}</b></p>
              </div>
              <ul class="desc-type-title baseline">
                <li>
                  <h4 style="width:90px">출석체크시간</h4>
                  <p>{{ timestampToDateFormat(item.objBgngDt, 'yyyy-MM-dd hh:mm:ss') }} ~ <br>{{ timestampToDateFormat(item.objEndDt, 'yyyy-MM-dd hh:mm:ss') }}</p>
                </li>
              </ul>
              <ul class="desc-type-title baseline">
                <li>
                  <h4 style="width:90px">참&nbsp;&nbsp;여&nbsp;&nbsp;인&nbsp;&nbsp;원</h4>
                  <p>{{ item.atdCnt }} / {{ item.totCnt }}</p>
                </li>
              </ul>
              <div class="kb-btn-actions">
                <button class="kb-btn-support mb-3 kb-btn-cream" style="width:47%" @click="openQrAttendStatus(idx)" v-if="item.lrnObjTyCd == '100030'">QR 출석 현황</button>
                <button class="kb-btn-support kb-btn-primary" style="width:47%;margin-left: 6%" @click="sendLink(idx)" v-if="item.lrnObjTyCd == '100030'">알림톡 발송</button>
                <button class="kb-btn-support kb-btn-cream" @click="openNowAttendStatus(idx)" v-if="item.lrnObjTyCd != '100030'">출석 현황</button>
              </div>
            </div>


          </section>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>

  <NowUsersStatus v-model="isNowAttend" v-if="isNowAttend" :objInfo="objInfo"  :distNm="crseDist.distCrseNm" />
  <QrAttendUsersStatus v-model="isQrAttend" v-if="isQrAttend" :objInfo="objInfo"  :distNm="crseDist.distCrseNm" />
  <NotiSendInfo v-model="isNotiSendInfo" v-if="isNotiSendInfo" :objInfo="objInfo" :distNm="crseDist.distCrseNm"/>
</template>

<script>
import {onMounted, ref} from "vue";
import {ACT_GET_QR_ATTEND_OBJ_LIST} from "@/store/modules/checklist/checklist";
import {getItems, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {goBackFunc} from "@/assets/js/modules/common/common";
import {useAlert} from "@/assets/js/modules/common/alert";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import QrAttendUsersStatus from "@/components/checklist/QrAttendUsersStatus.vue";
import NowUsersStatus from "@/components/checklist/NowUsersStatus";
import NotiSendInfo from "@/components/checklist/NotiSendInfo";

export default {
  name: 'AttendLink',
  components: {NotiSendInfo, QrAttendUsersStatus, NowUsersStatus},
  props: {
    crseDist : Object,

  },
  methods: {
    timestampToDateFormat,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const {showMessage} = useAlert();
    const items = ref(null);
    const objInfo = ref(null);
    const isNowAttend = ref(false);
    const isQrAttend = ref(false);
    const isNotiSendInfo = ref(false);

    const openNowAttendStatus=(idx)=>{
      objInfo.value = items.value[idx];
      isNowAttend.value = true;
      window.scrollTo(0, 0);
    }
    const openQrAttendStatus=(idx)=>{
      objInfo.value = items.value[idx];
      isQrAttend.value = true;
      window.scrollTo(0, 0);
    }

    const sendLink=(idx)=>{
      objInfo.value = items.value[idx];
      isNotiSendInfo.value= true;
      window.scrollTo(0, 0);
    }
    const getQrAttendObjList=()=>{

      // isLoadingCrse.value = true;
      store.dispatch(`checklist/${ACT_GET_QR_ATTEND_OBJ_LIST}`, route.params.distCrseSn).then((res) => {
        if(lengthCheck(res)){
          items.value = getItems(res);
        }else{
          showMessage('QR출석 객체가 없습니다.');
          goBackFunc();
        }
      })
    }

    onMounted(() => {
      getQrAttendObjList();
    })

    return{
      items,
      openNowAttendStatus,
      openQrAttendStatus,
      isNowAttend,
      isQrAttend,
      isNotiSendInfo,
      objInfo,
      sendLink
    }
  }
}

</script>