
<template>
  <main class="kb-main" id="kb-support" v-if="!isNotiSendInfo">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="search-result">
        <div class="view-contents">

          <!-- content-section -->
          <section v-for="(item, idx) in items" :key="idx" class="content-section">
            <div class="content-item bd-clear pd-clear">
              <div class="title">{{ item.chapNm}}</div>
              <div class="desc">
                <p><b>{{ item.objNm}}</b></p>
              </div>
              <ul class="desc-type-title baseline">
                <li>
                  <h4 style="width:60px">설문기간</h4>
                  <p>{{ timestampToDateFormat(item.objBgngDt, 'yyyy-MM-dd') }} ~ {{ timestampToDateFormat(item.objEndDt, 'yyyy-MM-dd') }}</p>
                </li>
              </ul>
              <ul class="desc-type-title baseline">
                <li>
                  <h4 style="width:60px">참여인원</h4>
                  <p>{{ item.atdCnt }} / {{ item.totCnt }}</p>
                </li>
              </ul>
              <div class="kb-btn-actions">
                <button class="kb-btn-support kb-primary" @click="sendLink(idx)">설문발송</button>
              </div>
            </div>

          </section>
        </div>
      </div>
    </div>
    <!-- //main-content -->

  </main>

  <NotiSendInfo v-model="isNotiSendInfo" v-if="isNotiSendInfo" :objInfo="objInfo" :distNm="crseDist.distCrseNm"/>

</template>

<script>
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";
import {onMounted, ref} from "vue";
import {ACT_GET_CRSE_SURVEY_LIST} from "@/store/modules/checklist/checklist";
import NotiSendInfo from "@/components/checklist/NotiSendInfo";
import {getItems, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {goBackFunc} from "@/assets/js/modules/common/common";

export default {
  name: 'CrseSurveylink',
  props: {
    crseDist : Object,

  },
  components:{
    NotiSendInfo
  },
  methods: {
    timestampToDateFormat,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const {showMessage} = useAlert();
    const items = ref(null);
    const objInfo = ref(null);
    const isNotiSendInfo = ref(false);
    const getCrseSurveyList=()=>{

      // isLoadingCrse.value = true;
      store.dispatch(`checklist/${ACT_GET_CRSE_SURVEY_LIST}`, route.params.distCrseSn).then((res) => {
        if(lengthCheck(res)){
          items.value = getItems(res);
        }else{
          showMessage('등록된 설문이 없습니다.');
          goBackFunc();
        }
      })
      //     .finally(() => {
      //   isLoadingCrse.value = false;
      // })
    };

    const sendLink =(idx)=>{
      objInfo.value = items.value[idx];
      isNotiSendInfo.value= true;
      window.scrollTo(0, 0);
    }

    onMounted(() => {
      getCrseSurveyList();
    })

    return{
      items,
      sendLink,
      objInfo,
      isNotiSendInfo
    }
  }
}

</script>