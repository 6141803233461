<template>
  <main class="kb-main" id="kb-checklist-dtl" v-if="!isLoadingCrse">
    <LxpMobileHeader :title="crseDist.distCrseNm" :header-class="false">
      <template v-slot:left>
        <div class="util util-back">
          <a class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back" />
          </a>
        </div>
      </template>
    </LxpMobileHeader>

    <div class="main-header main-header-visual">
      <div class="header-snb">
        <nav class="snb">
          <ul class="snb-list">
            <li class="snb-item">
              <button :class="{ 'is-active' : 'attend' === view }" @click="changeView('attend')">출석</button>
            </li>
            <li class="snb-item">
              <button :class="{ 'is-active' : 'research' === view }" @click="changeView('research')">설문</button>
            </li>
            <li class="snb-item">
              <button :class="{ 'is-active' : 'checklist' === view }" @click="changeView('checklist')">체크리스트</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>

  </main>

  <ChecklistDtl v-if="view === 'checklist'"/>
  <CrseSurveylink v-if="view === 'research'" :crseDist="crseDist" />
  <AttendLink v-if="view === 'attend'"  :crseDist="crseDist"/>
</template>


<script>
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader.vue";
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import ChecklistDtl from "@/views/pages/checklist/ChecklistDtl.vue";
import CrseSurveylink from "@/views/pages/checklist/CrseSurveylink.vue";
import AttendLink from "@/views/pages/checklist/AttendLink.vue";
import {ACT_GET_CRSE_DIST_INFO} from "@/store/modules/checklist/checklist";
import {getItem, lengthCheck} from "@/assets/js/util";
import {useAlert} from "@/assets/js/modules/common/alert";
import {useStore} from "vuex";

export default {
  name: 'ChecklistMain',
  components: {ChecklistDtl, CrseSurveylink, AttendLink, LxpMobileHeader},
  setup() {

    const route = useRoute();
    const router = useRouter()
    const store = useStore();
    const {showMessage} = useAlert();
    const distCrseSn = computed(()=> route.params.distCrseSn);

    const view = ref('attend');

    const changeView =(v)=>{
      view.value = v;
      router.push({name: 'ChecklistMain', params:{'distCrseSn': distCrseSn.value}, query:{'view': view.value}});
    }

    const goBack=()=>{
      router.push({name:'Checklist'}).then();
    }

    const isLoadingCrse = ref(true);
    const crseDist = ref(null);
    const getCrseDistInfo = () => {
      isLoadingCrse.value = true;
      store.dispatch(`checklist/${ACT_GET_CRSE_DIST_INFO}`, route.params.distCrseSn).then((res) => {
        if(lengthCheck(res)){
          crseDist.value = getItem(res);
        }else{
          showMessage('차수 정보를 불러오지 못했습니다.');
          goBack();
        }
      }).finally(() => {
        isLoadingCrse.value = false;
      })
    }

    onMounted(() => {
      getCrseDistInfo();
    })

    return{
      distCrseSn,
      view,
      changeView,
      crseDist,
      isLoadingCrse,
      goBack
    }
  }
}
</script>
